.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0; }

.clearfix:after {
  clear: both; }

.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.searchautocomplete > label {
  float: left;
  margin-right: 10px;
  color: #333;
  font-weight: bold;
  margin-top: 8px; }

.searchautocomplete .searchautocomplete-placeholder {
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  background: #FFF;
  border: 1px solid #ccc;
  padding: 15px 10px 5px 10px;
  position: absolute;
  right: 0;
  left: 0;
  top: 42px;
  z-index: 102;
  font-size: .75em; }

.searchautocomplete .searchautocomplete-placeholder:before {
  border-color: transparent transparent #CCC transparent;
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 5px;
  height: 0;
  width: 0;
  top: -11px;
  left: 30px; }

.searchautocomplete .searchautocomplete-placeholder ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.searchautocomplete .searchautocomplete-placeholder ul li {
  *zoom: 1;
  text-align: left;
  padding: 5px 5px;
  margin-bottom: 5px; }

.searchautocomplete .searchautocomplete-placeholder ul li:before,
.searchautocomplete .searchautocomplete-placeholder ul li:after {
  display: table;
  content: "";
  line-height: 0; }

.searchautocomplete .searchautocomplete-placeholder ul li:after {
  clear: both; }

.searchautocomplete .searchautocomplete-placeholder ul li a {
  text-decoration: none; }

.searchautocomplete .searchautocomplete-placeholder ul li a.name {
  color: #444;
  font-weight: bold;
  display: block;
  margin-bottom: 3px; }

.searchautocomplete .searchautocomplete-placeholder ul li a .pull-right {
  float: right; }

.searchautocomplete .searchautocomplete-placeholder ul li.active {
  background-color: #eeffee;
  cursor: pointer; }

.searchautocomplete .searchautocomplete-placeholder ul li.active a {
  text-decoration: none; }

.searchautocomplete .searchautocomplete-placeholder ul li .searchautocomlete-image {
  float: left;
  margin: 0px 10px 5px 0px; }

.searchautocomplete .searchautocomplete-placeholder ul li .price-box {
  font-weight: bold;
  font-size: 13px;
  color: #C76200;
  float: right; }

.searchautocomplete .searchautocomplete-placeholder ul li .price-box .regular-price .price-label,
.searchautocomplete .searchautocomplete-placeholder ul li .price-box .old-price .price-label,
.searchautocomplete .searchautocomplete-placeholder ul li .price-box .special-price .price-label {
  display: none; }

.searchautocomplete .searchautocomplete-placeholder ul li .price-box .price-label {
  font-weight: normal;
  color: #999; }

.searchautocomplete .searchautocomplete-placeholder ul li .price-box .old-price .price {
  font-size: 0.9em;
  color: #999; }

.searchautocomplete .searchautocomplete-placeholder ul li .highlight strong {
  color: #f00; }

.searchautocomplete .searchautocomplete-placeholder ul li .ratings {
  margin: 0;
  line-height: 14px; }

.searchautocomplete .searchautocomplete-placeholder ul li .ratings .rating-box {
  float: left;
  margin: 0 5px 0 0; }

.searchautocomplete .searchautocomplete-placeholder .index-title {
  text-align: right;
  color: #0A263C;
  border-bottom: 1px solid #CCC;
  padding: 2px 5px; }

.searchautocomplete .searchautocomplete-placeholder .index-title span {
  color: #666;
  font-size: 0.9em; }

.searchautocomplete .searchautocomplete-placeholder .all {
  text-align: right;
  margin: 10px 4px 5px 10px; }

.searchautocomplete .searchautocomplete-loader {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 9px;
  right: 10px;
  z-index: 99;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -o-transform: scale(0.6);
  transform: scale(0.6);
  display: none; }

.searchautocomplete .searchautocomplete-loader div {
  position: absolute;
  background-color: #FFFFFF;
  height: 3px;
  width: 3px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-animation-name: f_autocomplete_loader;
  animation-name: f_autocomplete_loader;
  -webkit-animation-duration: 0.64s;
  animation-duration: 0.64s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  animation-direction: normal; }

.searchautocomplete .searchautocomplete-loader div#g01 {
  left: 0px;
  top: 7px;
  -webkit-animation-delay: 0.24s;
  animation-delay: 0.24s; }

.searchautocomplete .searchautocomplete-loader div#g02 {
  left: 2px;
  top: 2px;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s; }

.searchautocomplete .searchautocomplete-loader div#g03 {
  left: 7px;
  top: 0px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

.searchautocomplete .searchautocomplete-loader div#g04 {
  right: 2px;
  top: 2px;
  -webkit-animation-delay: 0.48s;
  animation-delay: 0.48s; }

.searchautocomplete .searchautocomplete-loader div#g05 {
  right: 0px;
  top: 7px;
  -webkit-animation-delay: 0.56s;
  animation-delay: 0.56s; }

.searchautocomplete .searchautocomplete-loader div#g06 {
  right: 2px;
  bottom: 2px;
  -webkit-animation-delay: 0.64s;
  animation-delay: 0.64s; }

.searchautocomplete .searchautocomplete-loader div#g07 {
  left: 7px;
  bottom: 0px;
  -webkit-animation-delay: 0.72s;
  animation-delay: 0.72s; }

.searchautocomplete .searchautocomplete-loader div#g08 {
  left: 2px;
  bottom: 2px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s; }

@-moz-keyframes f_autocomplete_loader {
  0% {
    background-color: #000000; }
  100% {
    background-color: #ffffff; } }

@-webkit-keyframes f_autocomplete_loader {
  0% {
    background-color: #000000; }
  100% {
    background-color: #ffffff; } }

@-ms-keyframes f_autocomplete_loader {
  0% {
    background-color: #000000; }
  100% {
    background-color: #ffffff; } }

@-o-keyframes f_autocomplete_loader {
  0% {
    background-color: #000000; }
  100% {
    background-color: #ffffff; } }

@keyframes f_autocomplete_loader {
  0% {
    background-color: #000000; }
  100% {
    background-color: #ffffff; } }

.searchautocomplete-widget {
  position: relative;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.nav-search-in {
  display: none; }
